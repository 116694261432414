.bi-arrow-up-circle-fill{
    font-size: 2rem;
    display: flex;
    float: right;
    position: fixed;
    bottom: 2.5rem;
    right: 2.5rem;
    padding: 1rem;
    border-radius: 10%;
    background-color: #002D62;
    z-index: 999;
color: white;
    

}
.scrollbtn{
    bottom: 5rem;
    right: 5rem;
    cursor: pointer;
}
.bi-arrow-up-circle-fill{
    animation: scrollbtn 1.2s linear infinite alternate-reverse;
}
@keyframes scrollbtn {
    0%{
        transform: translateY(-0.3rem);
    }
    100%{
        transform: translateY(1rem);
    }
}

@media (min-width:0) and (max-width:630px) {
    .bi-arrow-up-circle-fill{
        padding: 1rem;
        bottom: 2rem;
        right: 1.5rem;
    }
}