.contact-page{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.contact-bg{
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    position: relative;
}
.contact-bg img{
    width: 100%;
}
.contact-name{
    width: 40%;
    /* background-color: antiquewhite; */
    position: absolute;
    top: 17rem;
    color: rgb(255, 255, 255);
    left: 5rem;
}
.contact-name h1{
    text-decoration: underline;
    cursor: pointer;
    font-size: 3.5rem;
    font-weight: 800;
}
.contact-name-img img{
    width: 120px;
}

.contact-touch{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin: 3rem 0;
}
.contact-touch-left{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.contact-touch-right{
    width: 40%;
    height: auto;
}
.contact-touch-right iframe{
    width: 100%;
    height: 480px;
    margin: 0 auto;
}


.touch-name h2{
    font-size: 1rem;
    text-decoration: underline;
    margin: 2rem 0;
}
.touch-name1 h1{
    font-size: 2.5rem;
    font-weight: 700;
    color: darkblue;
}
.details-1,.details-2,.details-3{
    display: flex;
    align-items: center;
  /* padding: 0.2rem 1rem; */
  border-radius: 0.5rem;
    background-color: #002f47;
    color: white;
    padding: 1rem;
    margin-top: 1rem; 
    /* text-align: center; */
}
.icons-name a{
    text-decoration: none;
    color: white;
}
.icons-name h1{
    font-size: 1.3rem;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;

}
.icons-name p{
    width: auto;
    font-weight: 700;
}
.icons-name{
    margin: 1rem;
}
.icons img{
    width: 60px;
    margin: 1rem;
}
.contact-bg-second{
    width: 100%;
    height: 50vh;
    background-image: url(./contact-bg.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

}

.contact-page-details{
    background-color: #002f47;
    height: auto;
    padding: 1rem;
}
.contact-page-details-box{
    width: 100%;
    height: auto;
    color: #ffffff;
    padding: 1rem 3rem; 
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    flex-direction: column;

}
.contact-page-details-box h2{
    font-size: 1rem;
    color: darkgreen;
    text-decoration: underline;
}
.contact-page-details-form{
    display: flex;
    width: 80%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
}
.contact-page-details-form-left{
    width: 50%;
}
.contact-page-details-form-right{
    display: flex;
    flex-direction: column;
}
.contact-page-details-form-left img{
    width: 100%;
}
.feedback-input {
    color:rgb(255, 255, 255);
    font-weight:500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    border:2px solid darkgreen;
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width:100%;
    box-sizing: border-box;
    outline:0;
  }
  
  .feedback-input:focus { border:2px solid darkgreen; }
  
  textarea {
    height: 150px;
    line-height: 150%;
    resize:vertical;
  }
  
  [type="submit"] {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    width: 100%;
    background:darkgreen;
    border-radius:5px;
    border:0;
    cursor:pointer;
    color:white;
    font-size:24px;
    padding-top:10px;
    padding-bottom:10px;
    transition: all 0.3s;
    margin-top:-4px;
    font-weight:700;
  }
  [type="submit"]:hover { background:blue; }



@media only screen and (min-width:0px) and (max-width:768px){
.contact-touch{
    flex-direction: column;
}
.contact-touch-left,.contact-touch-right{
    width: 90%;
}
.icons-name h1{
    font-size: 1rem;
}
.icons-name p{
    font-size: 0.7rem;
}
.contact-touch-right {
    /* margin-top: 1rem; */
    margin: 1rem auto;
}
.contact-page-details-form{
    flex-direction: column;
}

}


















@media only screen and (min-width:0px) and (max-width:768px){
    .contact-name{
        top: 6rem;
        left: 2rem;
    }
    .contact-name h1{
        font-size: 1rem;
    }
    .contact-name p{
        font-size: 0.6rem;
    }
    .contact-name-img img{
        width: 20px;
    }

}