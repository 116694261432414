.location{
    width: 100%;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 1rem auto;
    padding: 1rem;

}
.location-name{
    text-align: center;
}
.our-location{
    width: 70%;
    margin: 2rem auto;
    align-items: center;
    justify-content: space-around;
}
.location-1-line{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.location-1{
    width: 150px;
    text-align: center;
    margin: 0.2rem;
}
.location-1 h1{
    font-size: 1rem;
    background-color: #002f47;
    color: white;
    padding: 1rem 2rem;
}




@media only screen and (min-width:0px) and (max-width:768px){
    .location-1-line{
        /* justify-content: center; */
        align-items: center;
    }
    .location-1 h1{
        font-size: 0.7rem;
    }

}
