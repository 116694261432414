.features{
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    padding: 2rem;
    background-color: #002f47;
    color: white;
}
.features-left{
    width: 30%;
    display: flex;
    flex-direction: column;

}
.features-left-content h2{
font-size: 1rem;
text-decoration: underline;
color: darkgreen;
}
.features-left-content h1{
    font-size: 2.5rem;
    font-weight: 700;
    padding: 1rem 0rem;
}
.features-right{
    width: 70%;
    height: auto;
    align-items: center;
    justify-content: space-around;
    display: flex;
    margin: 2rem;
}
.box1 img{
    width: 100%;
    height: auto;
}

.box1{
    text-align: center;
    width: 98%;
    height: auto;
    /* padding: 1rem; */
    border: 1px solid rgb(255, 255, 255);
    margin-bottom: 1rem;
    cursor: pointer;
}
.box1 h1{
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem auto;
}

.box1:hover{
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.name h1{
    font-size: 1.5rem;
    margin-bottom: 1rem;

}




@media only screen and (min-width:0px) and (max-width:868px){
.features{
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
}
.features-left{
    width: 100%;
}
.features-right{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.feature-more-btn{
    margin-bottom: 1rem;
}
.box1{
    width: 100%;
    height: auto;
}

}