.costumer-text-slide{
    margin: 0rem auto;
    /* background: rgba(116, 112, 112, 0.5)url(./img2.jpg);
    background-position-x:left;
    background-blend-mode:hard-light; */
    /* background-color: yellow; */
    padding: 2rem;
    color: rgb(0, 0, 0);
}
.feedback-heading{
    text-align: center;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom:2rem;
}
.feedback-heading p{
    text-decoration: underline;
}
.feedback-heading h1{
    font-weight: 700;
}
.feedback{
    width: 70%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin:0 auto;
}
.feedback-img img{
    display: block;
    width: 30%;
    margin: 0 auto;
    height: auto;
    border-radius: 1rem;
}
.feedback-social-media{
    width: 100%;
    padding: 0.5rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.feedback-social-media i{
    box-shadow: 0 0 10px #eee;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    margin-left: 1rem;
    font-size: 1.5rem;
    color: #002f47;

}
.feedback-social-media i:hover{
    color: yellowgreen;
}


         
 




.feedback-btn-more a{
    box-shadow: 0 0 5px #eee !important;
}
.feedback-name h1{
    color: #958e8e;
    font-size: 1.5rem;
    text-decoration: underline;
}
.feedback-comment{
    width: 80%;
    font-size: 0.8rem;
    word-spacing: 0.3rem;
    padding: 0.5rem;
}
.feedback-box2{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.feedback-btn-more {
  margin-top: 1rem !important;

}
.carousel-control-prev-icon,.carousel-control-next-icon{
    padding: 2rem 1rem;
    background-color: rgb(116, 108, 108);
    color: #ffffff !important;
}



@media only screen and (min-width:0px) and (max-width:768px){
 .feedback{
    display: flex;
    flex-direction: column;
    padding: 0;
 }
 .feedback-box2{
    margin: 1rem auto;
    width: 100%;
    height: auto;
    text-align: center;
 }
 .feedback-img img{
    width: 50%;
    margin: 0 auto;
 }
 .feedback-btn-more a{
    padding: 0.5rem 1rem !important;
 }
 .feedback-comment{
    width: 100%;
 }
 .feedback-name h1{
    font-size: 1rem;
 }
 .feedback-comment p{
    font-size: 0.8rem;
 }
 .feedback-heading p{
   font-size: 0.8rem;
}

}