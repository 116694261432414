.about-content{
    display: flex;
    flex-direction: column;
    margin: 10rem auto;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-around;

}
.about-content-first{
  display: flex;
  width: 80%;
}
.about-content-left,.about-content-right{
    width: 50%;
}
.about-content-left h2{
    font-size: 1rem;
    color: darkgreen;
    font-weight: bold;
    text-decoration: underline;
}
.about-content-left h1{
    font-size: 38px;
    text-transform: capitalize;
    font-weight: bold;
}
.about-content-right img{
    width: 100%;
}
.discover-more a{
    font-size: 1rem !important;
    font-family: "Poppins", sans-serif;
}


  .about-content-second{
    display: flex;
    width: 100%;
    height: auto;
    background-color: #f5f7f2;
    justify-content: space-around;
    align-items: center;
    margin: 0rem auto;
  }

  .about-content-second-left{
    width: 40%;
    margin-top: 5rem;
  }
  .about-content-second-right{
    width: 60%;
  }
    .about-content-second-left img{
      width: 80%;
      border-radius: 5px;
      /* margin-top: 5rem; */
      margin-bottom: 18rem;
      margin-left: 2rem;
  }
  .about-content-second-right{
    display: flex;
    flex-direction: column;
  }
  .about-content-box{
    display: flex;
    margin: 0 auto;
  }
  .fa-bug,.fa-bug-slash{
    background-color: darkgreen !important;
    color: white !important;
  }
  .about-content-second-right h2{
    font-size: 1rem;
    color: darkgreen;
    text-decoration: underline;
  }
  .about-content-second-right h1{
    width: 80%;
    font-size: 50px;
    font-weight: bold;
  }
  .about-content-second-right p{
    width: 80%;
  }
.about-content-second-right-box1{
    width: auto;
    padding: 2rem;
    display: flex;
    margin: 1rem;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    border-radius: 10px;
  }

  .about-content-second-right-box2{
    padding: 2rem;
    margin: 5rem 5rem -3rem -5rem;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s ease-in;
    background-color: rgb(232, 232, 60);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  }

  .about-content-second-right-box1 h1,.about-content-second-right-box2 h1{
    font-size: 2rem;
  }
  .content-box-btn{
    margin-top: 1rem;
    margin-bottom: 1rem;
    
  }
  

  




/* CSS */
 .button-86{
  width: auto;
  
         }
.button-86 a{
  font-weight: bold;
  font-size: 0.8rem;
  text-decoration: none;
  background-image: linear-gradient(to right, #00c6ff 0%, #0072ff  51%, #00c6ff  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  border-radius: 10px;
}

.button-86 a:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}





@media only screen and (min-width:0px) and (max-width:768px){
.about-content{
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
}
.about-content-right img{
  width: 150%;
  display: flex;
  margin: 0 auto;
  margin-left: 2rem;
}
.about-content-first{
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
}
.about-content-left{
  padding: 1rem;
  width: 100%;
  align-items: center;
 }

.about-content-second,.about-content-box{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.about-content-second-right{
  width: 100%;
  height: auto;
  padding: 1rem;
  padding-bottom: -10rem;
  /* padding: 1rem; */
}
.about-content-second-left{
  width: 100%;
  height: auto;
  
  
}
.about-content-second-left img{
  width: 100%;
  display: flex;
  margin: 0rem auto;
}

.about-content-second-right-box1,.about-content-second-right-box2{
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  align-items: center;
  margin: 1rem auto;
  flex-direction: column;
  
}
}