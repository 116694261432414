.needhelp-background{
    /* background-color: #002f47; */
    /* color: white; */
    padding: 2rem;
    margin-top: 5rem
}
.needhelp{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    
}
.neehelp-1{
        width: 80%;
        /* background-color: aquamarine; */
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        text-align: center;
}


.neehelp-2{
    display: flex;
    margin: 3rem auto;
    text-align: center;
}
.needhelp-2-box{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    padding: 3rem;
    margin-left: 1rem;
    border-radius: 1rem;
    background-color: #002f47;
    color: white;
    cursor: pointer;
    
}
.needhelp-2-box:hover{
    box-shadow: #002f47 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px, rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px, rgb(255, 85, 85) 0px 0px 0px 15px;
    transition: 0.2s ease-in;
}
.needhelp-2-icons i{
    font-size: 2.5rem;
}
.needhelp-head h2{
    color: darkgreen;
    font-size: 1rem;
    text-decoration: underline;
}
.needhelp-2-name h1{
    font-size: 1.5rem;
    margin: 1rem;
    text-decoration: underline;
}
.needhelp-2-para p{
    font-size: 0.9rem;
    font-weight: 300;
    margin-bottom: 2rem;
}
.needhelp-2-btn a{
    padding: 1rem !important;
    /* font-size: 0.5rem !important; */
}



@media only screen and (min-width:0px) and (max-width:768px){

    .neehelp-1{
        width: 100%;

    }
    .needhelp-head h2{
        font-size: 0.8rem;
    }
    .neehelp-2{
        margin: 1rem auto;
        display: flex;
        flex-direction: column;
    }
    .needhelp-2-box{
        width: 100%;
        margin: 0 auto;
        padding: 2rem;
        margin-bottom: 1rem;

    }
    .needhelp-2-name h1{
        font-size: 0.9rem;
    }
    .needhelp-2-para p,.neehelp-1 p{
        font-size: 0.8rem;
    }
    .needhelp-2-btn{
        padding: 1rem !important;
    }
}