.about-page-main img{
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
}
.card{
  width: 18rem;
  height: auto;
}
.card-body a{
  padding: 1rem;
  text-decoration: none;
}
.New-page-left-content{
  width: 80%;
  height: auto;
  /* align-items: center; */
  margin: 1rem auto;
  justify-content: space-around;
  display: flex;
}
.page-point{
  align-items: center;
  margin: 3rem auto;
}
.page-right{
  width: 40%;
  height: auto;
  /* margin: 0 auto; */

}
.page-h1 h1{
  font-weight: 900;
  color: #002f47;
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 1.5rem;
  padding: 2rem  0rem;
}
.page-point ul li{
  list-style: circle;
text-decoration: underline;
}
.item-a{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  height: auto;
}
.ab-main-p{
  font-size: 1.1rem !important;
}

.container {
  display: grid;
  grid-template-columns: 50px 50px 50px 50px;
  grid-template-rows: auto;
  grid-template-areas: 
    "header header header header"
    "main main . sidebar"
    "footer footer footer footer";
}
.about-page{
  width: 100%;
  height: 100vh;
  background: rgba(167, 158, 158, 0.7)url(./bg06.jpg);
  /* background-position-x: left; */
  background-blend-mode:hard-dark;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin:0 auto;
  color: white;
  
}
.about-page-left {
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  width: 40%;
  height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.about-page-left h1{
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  
}
.about-page-left h4{
  font-size: 1rem;
}


.about-page-effect h2{
  font-size: 70px;
  letter-spacing: 0.2rem !important;
  margin-top: 0.5rem;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.10714286;
  font-family: "Poppins", sans-serif;
  color: white;
  text-decoration: none;
}


.about-page-effect h1 {
  font-size: 3rem;
  font-weight: bold;
  color: rgb(93, 30, 30);
  text-transform: uppercase;
  padding:0.5rem 1.5rem;
  font-family: "Poppins", sans-serif;
}
h1 a{
  text-decoration: none;
  color: yellow;
}
span {
  border-right: .05em solid;
  animation: caret 1s steps(1) infinite;
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}
.about-page h1{
 left: 0;

}

.about-page-effect{ 
  color:#002f47;
   text-decoration: none;
 
}
.about-page-left-content p{
  font-size: 1rem;
  /* color:#ffffff; */
  line-height: 1.7rem;
  font-weight: bold;
}

/* --------------button----------- */
.left-btn{
  width: auto;
 margin-top: 1rem;
}


         
.left-btn a{
  font-family: "Poppins", sans-serif;
  background-color: #178342;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
}
.left-btn a:hover{
  background-color: darkorange;
}



.about-footer{
  display: flex;
  width: 100%;
  height:auto;
  justify-content: space-evenly;
}
.about-page-footer-left{
  padding: 2.5rem;
  width: 50%;
  background-color: #002f47;
  align-items: center;
  justify-content: center;
  text-align: right;
  color: white;
  font-family: "Poppins", sans-serif;
}
.about-page-footer-left h2{
  color: white;
  word-spacing: 0.2rem;
  text-decoration: none;
}
.about-page-footer-left h1 a{
  color: white;
  text-decoration: none;
}
.about-page-footer-right{
  font-family: "Poppins", sans-serif;
  padding: 2.5rem;
  width: 50%;
  height: auto;
  background-color: white;
  color: black;
}
.about-page-footer-right h2,.about-page-footer-left h2{
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  padding: 0.5rem;
}
.chat-btn{
 margin-top: 2rem;
}

.about-page-content{
  font-family: "Poppins", sans-serif;
  width: 100%;
  height:auto;
  display: flex;
  margin: 0rem auto;
  align-items: center;
  justify-content: space-around;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
  background-color: #f5f7f2;
}
.content1{
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
}
.logo i{
  font-size: 2rem;
  margin-bottom: 1rem;
  background-color: darkgreen;
  color: white;
  padding: 2rem;
  border-radius: 50%;
}
.content1 h1{
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-align: center;
}
.content1 p{
  width: 300px;
  text-align: center;
  font-size: 0.9rem;
}

.content-overlap{
  width: 80%;
  display: flex;
  height: auto;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  margin: -3rem auto;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;

}
.overlap1{
  padding: 2rem;
  height: auto;
  background-color: #53adda;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;

}
.overlap-logo,.fa-star{
  color: rgb(232, 232, 60);
}


.overlap2{
  padding: 2rem;
height: auto;
color: white;
  background-color: #002f47;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.overlap3{
  padding: 2rem;
  height: auto;
  color: white;
  background-color: #53adda;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}
.overlap1 i,.overlap2 i,.overlap3 i{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 3rem;
}
.content-overlap h1{
  font-size: 1rem;
  margin-bottom: 1rem;
}


.about-page1-new-line{
  height: auto;

  margin-bottom: 5rem;
}




@media only screen and (min-width:0px) and (max-width:868px){
  .about-page{
    max-width: 768px;
    background: rgba(201, 198, 198, 0.5)url(./bg06.jpg);
    background-position : calc(60% - 2px) 0;
    background-size: cover;
  }
  .about-footer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .about-page-footer-left,.about-page-footer-right{
    width: 100%;
    /* height: 50vh; */
    margin: 0 auto;
    align-items: center;
  }
  .about-page-left h1,.about-page-left h4{
    font-size: 0.7rem;
  }
  .about-page-left h2,.about-page-effect h1{
    width: 250px;
    font-size: 1rem;
  }
  .chat-btn a{
    font-size: 0.7rem !important;
    padding: 0.7rem !important;
  }
  .about-page-content,.content-overlap{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .content-overlap{
    margin-top: 1rem;
    padding: 1rem;
  }
  .item-a{
    flex-direction: column;
  }
  .New-page-left-content{
    flex-direction: column-reverse;
  }
  .page-right{
    width: 90%;
    height: auto;
    margin: 0 auto;
  
  }
}

