.counting{
    display: flex;
    width: 100%;
    padding: 2rem;
    height: auto;
    align-items: center;
    justify-content: space-around;
    background-color: #f5f7f2;
    margin: 0 auto;
}
.counting h1{
    font-size: 3.8rem;
    font-weight: 700;
    color: #002f47;
    text-align: center;
}

@media only screen and (min-width:0px) and (max-width:768px){
    .counting{
        padding:1rem 0.5rem;
    }
    .counting h1{
        font-size: 1rem;
        text-align: center;
    }
    .counting p{
        font-size: 0.5rem;
    }

}