

/* ---------------services-pages-Start--------------------- */
.service-page-1{
    display: flex;
    width: 80%;
    /* padding: 2rem; */
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
}
.services-page-left-para{
    width: 70%;
}
.services-page-left-head h1{
    font-size: 3rem;
    font-weight: 800;
    margin: 2rem 0rem;
}
.services-page-left-btn{
    width: 30%;
    margin-bottom: 1rem !important;
}
.services-page-right-img img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: auto;

}
/* --------------services-pages-End------------------------ */


/* ---------------------service-page-description-start------ */

#current{
    display: block;
}
.service-details{
    display: none;
}

.service-page-description-left-other{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: -1rem auto;
    /* flex-direction: column; */
}
.service-page-description-left-head-name h1{
    font-size: 1.5rem;
    font-weight: 700;
}
.fa-check-double{
    margin-right: 1rem;
}
.features-right{
    width: 98%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}


/* Styling for main sections */
main {
    /* display: none; */
	max-width: 800px;
	margin: 0 auto;
}

h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #002f47;
    text-decoration: underline;
	margin-bottom: 20px;
}

p {
	line-height: 1.5;
	margin-bottom: 20px;
}

ul {
	margin-bottom: 20px;
}
ul li{
    list-style: none;
}
.fa-circle-check{
    margin-right: 1rem;
    color: darkgreen;
}
button {
	background-color: #333;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}


/* ---------------------service-page-description-end------ */

/* ------------------service-page-common-question-start--------- */
.service-page-common-question{
width: 100%;
height: auto;
padding: 1rem;
background-color: #002f47;
color: white;
margin: 0 auto;
text-align: center;
}
.service-page-common-img{
    width: 80%;
    margin: 0 auto;
}
.service-page-common-img img{
    width: 50%;
}
.common-head-name1 h2{
    font-size: 1rem;
    text-decoration: underline;
    color: white;
}

/* ------------------service-page-common-question-end----------- */




.services-page{
    margin-top: 5rem;
}
.services{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    /* margin-top: -10rem; */
    font-family: "Poppins", sans-serif;
}
.services-header{
    width: 50%;
    height: auto;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.services-name h2{
    color: darkgreen;
    font-size: 1.2rem;
    letter-spacing: 0.5rem;
    font-family: "Poppins", sans-serif;
    text-decoration: underline;
    margin-bottom: 1rem;
}
.services-head-name h1{
    font-size: 3rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
    color: #002f47;
}
.services-head-content p{
    font-size: 1rem;
    color: darkgreen;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;
}


.card-left {
    width: 500px; /* adjust the width and height to fit your image */
    height: 500px;
    overflow: hidden; /* to hide the parts of the image that go outside the container */
  }
  
  .card-img img {
    width: 100%; /* to make the image fill the container */
    height: auto;
    transition: transform 0.5s; /* to add a smooth transition effect */
  }
  
  .card-details:hover img {
    /* border-radius: 2rem; */
    transform: scale(1.2); /* to zoom in the image on hover */
  }
  


.services-card{
    width: 100%;
    height: auto;
    display: flex;
    /* flex-direction: ; */
    justify-content: space-around;
    align-content: center;
    margin: 1rem auto;
    
}
.card-details{
    width: 48%;
    height: auto;
    display: flex;
    padding: 1rem;
   align-items: center;
   justify-content: center;
   margin: 0 auto;
    background-color: #f5f7f2;
    border-radius: 1rem;
}
.card-left{
    width: 170%;
    height: auto;
}

.card-img img{
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.card-right{
    padding: 2rem;
}
.card-head h1{
    color: #002f47;
    font-size: 1.5rem;
    font-weight: bold;
}

.card-btn-more{
    margin-top: 2rem !important;
}

.services-more{
    width: 100%;
    color: #002f47;
    display: flex;
    margin: 1rem auto;
    align-items: center;
    justify-content: center;
}
.services-more a{
    width: 200px !important;
}




         
         
         
.btn-grad {
    cursor: pointer;
    background-image: linear-gradient(to right, #F7971E 0%, #FFD200  51%, #F7971E  100%);
    margin: 10px;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 10px #eee;
    border-radius: 10px;
    display: block;
  }
  .btn-grad a{
    padding: 1rem;
text-decoration: none;
color: rgb(251, 251, 251);
font-size: 0.9rem;
font-weight: bold;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
  }
 


  @media only screen and (min-width:0px) and (max-width:768px){
    .services-card,.card-details,.card-left,.card-right{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .card-left{
        margin-top: 1rem;
    }
    .services-header{
        width: 80%;
    }
    .services-head-content p{
        width: 100%;
    }
    .service-page-1{
        display: flex;
        flex-direction: column-reverse;
        padding: 1rem;
    }
    .services-page-left-btn{
        width: 100%;
    }
    .service-page-description{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }
    .service-page-description-right{
        width: 100%;
        margin: 1rem auto;
    }
    .service-page-common-question{
        flex-direction: column;
    }
    .box{
        display: flex;
        flex-direction: column;
    }
    .name1 h1{
        font-size: 0.5rem;
    }
  }
 

         
         
 
 