
.header {
  width: 100%;
  height: 4.5rem;
  position: sticky;
  top: 0 !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-around;
  border-bottom: 1px solid rgb(140, 131, 131);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
   /* background-image: linear-gradient(to bottom, #34acac 0%, #ab7be5 100%); */
   background-color: white;
  z-index: 9999;
}
.logo{
  display: flex;
}
.logo img{
  width: 9vw;
  height: auto;
  
}
.logo h1{
  display: flex;
  margin-left: 2rem;
  align-items: center;
  font-size: 1.3rem;
  font-family: 'Merriweather Sans', sans-serif;
}

.navbar ul {
  margin: 0 auto;
  display: flex;
}
.navbar ul li {
  list-style: none;
  display: block;
  padding: 1rem;
}
.navbar ul li .list{
  cursor: pointer;
  /* color: rgb(255, 255, 255); */
  color: black;
  font-weight: 500;
  font-size: 0.9rem;
  text-decoration: none;
  text-transform: uppercase;
  padding:0.5rem 1.5rem;
  font-family: "Poppins", sans-serif;

}
.navbar ul li a:hover{
  border-radius: 5px;
  /* background-image: linear-gradient(to right, #00b09b, #96c93d); */
  background-color: #002f47;
  color: rgb(255, 255, 255);
}
.bi-envelope-check-fill{
  cursor: pointer;
}
.bi-envelope-check{
  margin-right: 1rem;
}
.right-collapse{
  display: none;
}
.right-collapse button{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.btn{
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.offcanvas-body ul li{
  padding: 1rem;
}
         
.offcanvas-body ul li a {
  background-image: linear-gradient( 109.6deg,  rgba(61,245,167,1) 11.2%, rgba(9,111,224,1) 91.1% );
  text-align: center;
  font-size: 1rem;
  transition: 0.5s;
  text-decoration: none;
  border-radius: 5px;
  background-size: 200% auto;
  color: rgb(0, 0, 0);            
  box-shadow: 0 0 0px #eee;
  padding: 0.5rem 1.5rem;
}

.offcanvas-body ul li a:hover {
  border-radius: 5px;
  background-image: linear-gradient(to right, #00b09b, #96c93d);
  color: rgb(255, 255, 255);
}

         













         
.offcanvas{
  width: 300px !important;
  background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
}

.offcanvas:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}








.offcanvas-body .resume{
  margin: 1rem auto;
  
}
.col{
  width: 300px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.col a i{
  color: rgb(0, 0, 0);
 
}

.col a i:hover{
      color: #000000;
    }     



.callbtn{
  display: block;
  width: auto;
  position: fixed;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  bottom: 0;
  background-color: #002f47 !important;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
}

.callbtn a{
  text-align: center;

  color: white;
  text-decoration: none;
  
}

.right-header a{
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  background-color: #002f47;
  color: white;
}







.col a{
  font-size: 2rem;
}
.right-header i{
  color: black;
  font-size: 1.5rem;
}
@media (min-width: 0px) and (max-width: 1024px) {
  .logo img{
    width: 25vw;
  }
  .navbar {
    display: none;
  }
  .right-collapse {
    display: block;
  }
  .collapse-navbar li {
    list-style: none;
    padding-top: 1rem;
  }
  .collapse-navbar li a {
    color: #121212;
    font-size: 1.3rem;
  }
  .right-header a{
    font-size: 0.8rem;
  }

  
}
