.footer-all-details{
    width: 100%;
    background-color: black;
}
.footer{
    margin: 0 auto;
    display: flex;
    padding: 3rem;
    width: 90%;
    color: white;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    display: flex;
    
}
.footer-col1 p{
    font-size: 1rem;
    text-decoration: none !important;
}
.footer p{
    text-decoration: underline;
}
.footer li{
    list-style: none;
}
.footer li a{
    text-decoration: none;
}
.footer-col1,.footer-col2,.footer-col3,.footer-col4{
    display: flex;
    flex-direction: column;
}
.footer li{
    padding: 0.3rem 0rem;
}
.footer-col4 li a i{
    margin-right: 1rem;
}
.footer-make-name{
    width: 100%;
    height: auto;
    color: white;
    display: flex;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    background-color: #002f47;
}
.footer-make-name p{
 align-items: center;   
 justify-content: center;
 margin: 0 auto;
 padding: 1rem;
}
.fa-heart{
    color: red;
}

.made-name a{
    
    margin-left: 0.5rem;
}
.termcondition{
    padding: 1.5rem;
    width: 80%;
}
.whatsapp{
    position: fixed;
    bottom: 8rem;
    right: 1rem;
}


@media only screen and (min-width:0px) and (max-width:768px){
.footer{
    flex-direction: column;
    width: 100%;
    align-items: normal;
}
.footer p{
    margin-top: 1rem;
    font-size: 0.7rem;

}
.footer-make-name p{
    font-size: 0.7rem;
}
.whatsapp img{
    width: 70px;
}


}